<template>
    <div class="alert bg-light-warning py-4 px-6 mb-10 border-warning border" v-if="showVideoCompressWarning">
        <div class=" text-dark">
            <div class="d-flex align-items-center mb-2">
                <h1 class="fw-bold mb-5 text-warning me-3">{{ $t('messages.warning') }}</h1>
                <span class="svg-icon svg-icon-3x svg-icon-warning mb-5">
                    <inline-svg src="/media/icons/duotune/general/gen044.svg"/>
                </span>
            </div>
            <div class="fs-6 mb-3 text-gray-700">{{ $t('pages.video.detail.warning.videoCompress.text1') }}</div>
            <div class="separator separator-dashed border-warning opacity-50 mb-3"></div>
            <div class="fs-6 text-gray-700">{{ $t('pages.video.detail.warning.videoCompress.text2') }}</div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-5 col-xl-3 mt-7 mt-md-0">
            <div class="card mb-5 mb-xl-8">
                <div class="card-body pt-15">
                    <div class="d-flex flex-center flex-column mb-5">
                        <div class="symbol symbol-150px mb-7">
                            <img :src="((video.images && video.images.length) ? ((typeof(video.images[1]) !== 'undefined') ? video.images[1].direct_link : video.images[0].direct_link) : '/media/svg/files/blank-image.svg')" alt="image"/>
                        </div>

                        <span class="fs-3 text-gray-800 fw-bolder mb-1 text-center">
                           {{ video.title }}
                        </span>

                        <span class="fs-5 fw-bold text-muted mb-6">
                            {{ video.duration_text }} / {{ ((video.size_detail) ? video.size_detail.text : '') }}
                        </span>

                        <el-popconfirm v-if="video.id" :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord">
                            <template #reference>
                                <a class="btn btn-danger">
                                    <i class="bi bi-trash fs-4 me-2"></i>
                                    {{ $t('pages.video.detail.deleteContent') }}
                                </a>
                            </template>
                        </el-popconfirm>
                    </div>
                    <div class="separator separator-dashed my-3"></div>
                    <div class="text-center fs-4 py-3">
                        <span class="fs-5 text-gray-800 fw-muted mb-1 mt-3">
                            {{ $moment(video.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
                        </span>
                        <div class="fs-7 text-gray-800 fw-muted mt-4">
                            <b class="d-block">{{ video.id }}</b>
                            <span v-if="video.uuid">{{ video.uuid }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-5 mb-xl-8" v-if="video.options && typeof(video.options) == 'object' && !Array.isArray(video.options) && Object.keys(video.options).length">
                <div class="card-header">
                    <h2 class="card-title fw-bolder">{{ $t('pages.video.detail.options.title') }}</h2>
                </div>
                <div class="card-body">
                    <div class="d-flex flex-stack pb-5 fs-6 mt-5" v-for="(option, optionKey, optionIndex) in video.options" :key="optionIndex">
                        <div class="fw-bolder">
                            {{ $t('pages.video.detail.options.type.' + optionKey, optionKey) }}
                        </div>
                        <div class="text-gray-600">
                            <span v-if="typeof(option) == 'boolean'" class="badge" :class="option ? 'badge-success' : 'badge-danger'">{{ $t('common.' + ((option) ? 'yes' : 'no')) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-5 mb-xl-8" v-if="video.process_statuses && video.process_statuses.length">
                <div class="card-header">
                    <h2 class="card-title fw-bolder">{{ $t('pages.video.detail.processState.title') }}</h2>
                </div>
                <div class="card-body">
                    <div class="pb-5 fs-6" v-for="(processState, processStateIndex) in video.process_statuses" :key="processStateIndex">
                        <div class="fw-bolder mt-5">
                            {{ $t('pages.video.detail.processState.type.' + processState.type, processState.type) }}
                        </div>
                        <div class="progress mt-2">
                            <el-progress class="w-100" status="success" :text-inside="true" :stroke-width="20" :percentage="processState.progress"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-5 mb-xl-8" v-if="video.extra && typeof(video.extra) == 'object' && !Array.isArray(video.extra) && Object.keys(video.extra).length">
                <div class="card-header">
                    <h2 class="card-title fw-bolder">{{ $t('pages.video.detail.extras.title') }}</h2>
                </div>
                <div class="card-body">
                    <div class="d-flex flex-stack pb-5 fs-6 mt-5" v-if="video.extra.size_changed">
                        <div class="fw-bolder">
                            {{ sprintf($t('pages.video.detail.extras.sizeChanged.old'), [bytesToSize(video.extra.size_changed.old)]) }}
                        </div>
                        <div class="text-gray-600">
                            {{ bytesToSize(video.extra.size_changed.new) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-7 col-xl-9">
            <el-tabs v-model="tabModel" class="product-tabs" @tab-click="changedTab">
                <el-tab-pane :label="$t('pages.video.detail.tab.general')" name="general">
                    <el-form :model="form.data" ref="videoForm">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.video.detail.cols.title') }}</label>
                                    <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.title" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.video.detail.cols.group') }}</label>
                                    <el-form-item prop="group_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.group_id" :placeholder="$t('common.chooseSelect')" class="w-100" filterable>
                                            <el-option v-for="(group, groupIndex) in videoGroups" :key="groupIndex" :value="group.id" :label="group.name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <div class="card mt-7 pt-4 mb-6 mb-xl-9">
                        <div class="card-body pt-5 pb-5">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">
                                    {{ $t('pages.video.detail.copyUrl.title') }}
                                </label>
                                <div class="alert alert-secondary mt-2" role="alert" v-if="$moment(video.created_at).unix() > 1703462400 && (getVideoProgressState('CONVERT_COMPRESSION', {}).progress !== 100)">
                                    <p class="mb-0">{{ $t('pages.video.detail.warning.videoCompress.videoLink') }}</p>
                                </div>
                                <div class="d-flex" v-else>
                                    <input @click="copyUrl(video.direct_link)" :value="video.direct_link" type="text" class="form-control form-control-solid me-3 flex-grow-1" readonly>
                                    <button @click="copyUrl(video.direct_link)" class="btn btn-light fw-bold flex-shrink-0">{{ $t('btn.copy') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <custom-table
                        v-if="video.streams"
                        :title="$t('pages.video.detail.stream.title')"
                        :subTitle="$t('pages.video.detail.stream.subTitle')"
                        class="mt-6"
                        :items="video.streams"
                        :columns="stream.fields">
                        <template v-slot:type="{ row: record }">
                            {{ record.type.code }} - <b>{{ record.type.name }}</b>
                        </template>
                        <template v-slot:size="{ row: record }">
                            {{ bytesToSize(record.size) }}
                        </template>
                        <template v-slot:direct_link="{ row: record }">
                            <span @click="copyUrl(record.direct_link)">{{ record.direct_link }}</span>
                        </template>
                    </custom-table>
                </el-tab-pane>
                <el-tab-pane :label="$t('pages.video.detail.tab.image')" name="image" v-if="video.images && video.images.length">
                    <div class="card pt-4 mb-6 mb-xl-9">
                        <div class="card-header border-0">
                            <div class="card-title">
                                <h2>{{ $t('pages.video.detail.videoImage.title') }}</h2>
                            </div>
                        </div>
                        <div class="card-body pt-0 pb-5">
                            <div class="row row-cols-1 row-cols-md-4">
                                <div class="col" v-for="(image, imageIndex) in video.images" :key="imageIndex">
                                    <div class="fv-row mb-7">
                                        <a :href="image.direct_link" target="_blank">
                                            <img :src="image.direct_link" style="width: 100%">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('pages.video.detail.tab.video')" name="video">
                    <div class="card pt-4 mb-6 mb-xl-9">
                        <div class="card-body pt-5 pb-9">
                            <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6" v-if="video.streams && video.streams.length">
                                <li class="nav-item">
                                    <a class="nav-link cursor-pointer" @click="videoTab = 'original'" :class="[videoTab == 'original' && 'active']">{{ $t('pages.video.videoTab.original') }}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link cursor-pointer" @click="videoTab = 'hls'" :class="[videoTab == 'hls' && 'active']">{{ $t('pages.video.videoTab.hls') }}</a>
                                </li>
                            </ul>
                            <CustomVideoPlayer v-if="videoTab == 'original'" :src="video.direct_link"></CustomVideoPlayer>
                            <CustomVideoPlayer v-if="videoTab == 'hls'" :src="video.streams[0].direct_link" hlsQualitySelector></CustomVideoPlayer>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import CustomVideoPlayer from "@/components/custom-video-player";

export default {
    name: "_id",
    components: {
        CustomTable,
        CustomVideoPlayer
    },
    data() {
        return {
            tabModel: 'general',
            video: {},
            form: {
                loading: false,
                data: {},
            },
            stream: {
                fields: [
                    {
                        name: this.$t("pages.video.detail.stream.cols.type"),
                        scopedSlots: {customRender: "type"},
                    },
                    {
                        name: this.$t("pages.video.detail.stream.cols.size"),
                        scopedSlots: {customRender: "size"},
                    },
                    {
                        name: this.$t("pages.video.detail.stream.cols.url"),
                        scopedSlots: {customRender: "direct_link"},
                        style: "text-align:left !important",
                        rowStyle: "text-align:left !important",
                    }
                ],
            },
            videoTab: undefined,
        }
    },
    computed: {
        videoID() {
            return this.$route.params.id;
        },
        videoGroups() {
            return this.$store.state.video.group.table.data;
        },
        showVideoCompressWarning() {
            let showStatus = false;

            if (this.video && this.video.process_statuses) {
                let state = this.getVideoProgressState('CONVERT_COMPRESSION')
                if (state) {
                    if (state.progress !== 100) {
                        showStatus = true;
                    }
                } else {
                    showStatus = true;
                }
            }

            return showStatus;
        }
    },
    created() {
        if (this.videoID && !((this.videoID > 0) || (new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$').test(this.videoID)))) {
            this.$router.push({
                path: "/video"
            });
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.video.detail.title"), [this.$t("menu.contentManagement.title"), this.$t("menu.contentManagement.video.title")]);

        if (this.videoID && ((this.videoID > 0) || (new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$').test(this.videoID)))) {
            this.loadVideo();
            this.$store.dispatch('video/group/get', {
                page: {pageSize: 9999}
            });
        }
    },
    methods: {
        loadVideo() {
            this.axios.get(this.endpoint.get('video') + '/' + this.videoID).then(response => {
                let data = response.data.data;
                this.video = this.cloneData(data);
                this.form.data = data;
            });
        },
        onSubmit() {
            this.$refs.videoForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoint.get('video') + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadVideo();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord() {
            this.$store.dispatch("video/delete", {
                id: [this.video.id]
            }).then((successDeleted) => {
                if (successDeleted) {
                    setTimeout(() => {
                        this.$router.push({path: "/video"});
                    }, 500)
                }
            });
        },
        copyUrl(url) {
            navigator.clipboard.writeText(url).then(() => {
                this.$swal.fire({
                    icon: 'success',
                    title: this.$t('pages.video.detail.copyUrl.copied'),
                    showConfirmButton: false,
                    timer: 750,
                });
            });
        },
        changedTab() {
            if (this.tabModel == 'video' && !this.videoTab) {
                this.videoTab = 'original'
            } else {
                this.videoTab = undefined;
            }
        },
        getVideoProgressState(stateType, defaultValue = null) {
            if (this.video && this.video.process_statuses) {
                let foundIndex = this.video.process_statuses.findIndex((state) => state.type === stateType);

                if (foundIndex !== -1) {
                    return this.video.process_statuses[foundIndex];
                }
            }

            return defaultValue;
        }
    }
}
</script>

<style scoped>

</style>